<template>
    <v-container v-if="!cookie" fluid class="cookie pa-1 mb-5">
        <v-card class="mx-5" flat>
            <v-container class="white--text d-flex justify-space-between align-center">
                <p class="mb-0 text-14-45">
                    This website use cookies. To find out more see our <router-link class="white--text rl" :to="{ name: 'frontend.cookies', params: {} }">Cookies Policy</router-link>
                </p>
                <v-btn
                    outlined
                    color="white"
                    class="text-14-45"
                    @click="setCookies()"
                >
                    Got it!
                </v-btn>
            </v-container>
        </v-card>
    </v-container>

</template>

<script>
import Cookies from 'js-cookie'
export default {
    data: () => ({
        cookie: false,
    }),
    mounted(){
        this.cookie = Cookies.get('cookies');
    },
    methods: {
        setCookies(){
            Cookies.set('cookies', true, {expires: 365})
            this.cookie = true;
        }
    }
}
</script>

<style lang="scss">
.cookie{
    position: fixed;
    bottom: 0;
    z-index: 99999;
    .v-card{
        background: rgba(61, 71, 91, 0.3)!important;
        .v-btn__content{
            text-transform: none;
        }
        .rl{
            text-decoration: underline;
        }
    }
}
</style>
