<template>
    <v-app>
        <transition name="fade" mode="out-in" appear>
            <preloader v-if="preloader" />
            <router-view v-if="!preloader"/>
        </transition>
        <cookies v-if="cookie"/>
    </v-app>
</template>

<script>

import Preloader from '@/components/base/Preloader.vue'
import Cookies from '@/components/base/Cookies.vue'

export default {
    name: 'App',
    components: {
        Preloader,
        Cookies
    },
    data: () => ({
        preloader: false,
        cookie: false
    }),
    created(){
    },
    mounted(){
        this.preloader = false;
        setTimeout(() => this.preloader = false, 2000);
        setTimeout(() => this.cookie = true, 5000);
    }

};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>
