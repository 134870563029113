function page(path){
    return () => import('../../views/' + path).then(m => m.default || m)
}

export default {
    path: '/',
    component: page('frontend.vue'),
    meta: {
        title: 'Transportation company specialized in making time-limited deliveries',
    },
    children: [
        {
            path: '/',
            name: 'frontend.home',
            component: page('frontend/homepage.vue'),
            meta: {
                title: 'Transportation company specialized in making time-limited deliveries',
                // metaTags: [
                //     {
                //         name: 'description',
                //         content: 'The home page of our example app.'
                //     },
                //     {
                //         property: 'og:description',
                //         content: 'The home page of our example app.'
                //     }
                // ]
            }
        },
        {
            path: '/about',
            name: 'frontend.about',
            component: page('frontend/about.vue'),
            meta: {
                title: 'About us · Freight Crew',
            }
        },
        {
            path: '/agent',
            name: 'frontend.agent',
            component: page('frontend/agent.vue'),
            meta: {
                title: 'Join Our Team Become an Agent · Freight Crew',
            }
        },
        {
            path: '/contact',
            name: 'frontend.contact',
            component: page('frontend/contact.vue'),
            meta: {
                title: 'Contact Us · Freight Crew',
            }
        },
        {
            path: '/terms',
            name: 'frontend.terms',
            component: page('frontend/terms.vue'),
            meta: {
                title: 'Terms and Conditions · Freight Crew',
            }
        },
        {
            path: '/cookies',
            name: 'frontend.cookies',
            component: page('frontend/cookie.vue'),
            meta: {
                title: 'Cookie Policy · Freight Crew',
            }
        },
        {
            path: '/privacy',
            name: 'frontend.privacy',
            component: page('frontend/privacy.vue'),
            meta: {
                title: 'Privacy Policy · Freight Crew',
            }
        },
        {
            path: '/blog',
            name: 'frontend.blog',
            component: page('frontend/blog.vue'),
            meta: {
                title: 'Blog · Freight Crew',
            }
        },
        {
            path: '/careers',
            name: 'frontend.careers',
            component: page('frontend/careers.vue'),
            meta: {
                title: 'Careers · Freight Crew',
            }
        },
        {
            path: '/careers/:slug',
            name: 'frontend.career',
            component: page('frontend/career.vue'),
        },
        {
            path: '/services/ltl',
            name: 'frontend.services.ltl',
            component: page('frontend/services/ltl.vue'),
            meta: {
                title: 'LTL Shipping · Freight Crew',
            }
        },
        {
            path: '/services/international',
            name: 'frontend.services.international',
            component: page('frontend/services/international.vue'),
            meta: {
                title: 'International Shipping · Freight Crew',
            }
        },
        {
            path: '/services/airfreight',
            name: 'frontend.services.airfreight',
            component: page('frontend/services/airfreight.vue'),
            meta: {
                title: 'Airfreight Shipping · Freight Crew',
            }
        },
        {
            path: '/services/expedited',
            name: 'frontend.services.expedited',
            component: page('frontend/services/expedited.vue'),
            meta: {
                title: 'Expedited Shipping · Freight Crew',
            }
        },
        {
            path: '/track',
            name: 'frontend.track',
            component: page('frontend/track.vue'),
            meta: {
                title: 'Track shipment · Freight Crew',
            }
        },
        {
            path: '/auth',
            name: 'frontend.auth',
            component: page('frontend/auth/main.vue'),
            redirect: '/auth/login',
            children: [
                {
                    path: 'login',
                    name: 'frontend.auth.login',
                    component: page('frontend/auth/login.vue'),
                    meta: {
                        title: 'Login · Freight Crew',
                    }
                },
                {
                    path: 'registration',
                    name: 'frontend.auth.register',
                    component: page('frontend/auth/register.vue'),
                    meta: {
                        title: 'Registration · Freight Crew',
                    }
                },
                {
                    path: 'recovery',
                    name: 'frontend.auth.reset',
                    component: page('frontend/auth/reset.vue'),
                }
            ]
        },
        {
            path: 'quote',
            name: 'frontend.calculator',
            component: page('frontend/calculator.vue'),
            meta: {
                title: 'Make Quote · Freight Crew',
            }
        },

    ]
}
