import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import { sync } from 'vuex-router-sync'

Vue.use(VueRouter)

const router = createRouter();
const globalMiddleware = [/*'locale',*/ 'check_auth']
const routeMiddleware = resolveMiddleware(
    require.context('@/middleware', false, /.*\.js$/)
)

sync(store, router)

export default router

function createRouter(){
    const router = new VueRouter({
      mode: 'history',
      routes
    })

    router.beforeEach(beforeEach)
    router.afterEach(afterEach)

    return router
}

async function beforeEach (to, from, next) {
    let components = []

    try {
        components = await resolveComponents(
          router.getMatchedComponents({ ...to })
        )
    } catch (error) {
        if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
          window.location.reload(true)
          return
        }
    }

    if (components.length !== 0){
        // Start the loading bar.
        if (components[components.length - 1].loading !== false) {
            // router.app.$nextTick(() => router.app.$loading.start())
        }
        // Get the middleware for all the matched components.
        const middleware = getMiddleware(components)

        // Call each middleware.
        callMiddleware(middleware, to, from, (...args) => {
            // Set the application layout only if "next()" was called with no args.
            // if (args.length === 0) {
            //     router.app.setLayout(components[0].layout || '')
            // }


            next(...args)
        })
    }

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if(nearestWithMeta){
        nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });

            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        }).forEach(tag => document.head.appendChild(tag));
    }

    return next();
}

async function afterEach (to, from, next) {
    await router.app.$nextTick()
    // router.app.$loading.finish()
}

function resolveComponents(components) {
    return Promise.all(components.map(component => {
        return typeof component === 'function' ? component() : component
    }))
}


function getMiddleware (components) {
    const middleware = [...globalMiddleware]

    components.filter(c => c.middleware).forEach(component => {
        if (Array.isArray(component.middleware)) {
            middleware.push(...component.middleware)
        } else {
            middleware.push(component.middleware)
        }
    })

    return middleware
}

function callMiddleware (middleware, to, from, next) {
    const stack = middleware.reverse()
    const _next = (...args) => {
        // Stop if "_next" was called with an argument or the stack is empty.
        if (args.length > 0 || stack.length === 0) {
            if (args.length > 0) {
                // router.app.$loading.finish()
            }

            return next(...args)
        }

        const middleware = stack.pop()

        if (typeof middleware === 'function') {
            middleware(to, from, _next)
        } else if (routeMiddleware[middleware]) {
            routeMiddleware[middleware](to, from, _next)
        } else {
            throw Error(`Undefined middleware [${middleware}]`)
        }
    }

    _next()
}

function resolveMiddleware (requireContext) {
    return requireContext.keys()
    .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
    .reduce(
        (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
    {})
}
