import * as types from '../mutation-types'

export const track = {
    namespaced: true,
    state: {
        track: null,
    },
    getters: {
        track: (state) => {
            return state.track
        },
        getTrack: state => state.track
    },
    mutations: {
        [types.TRACK_ASSIGN](state, track){
            state.track = track
        }
    },
    actions: {
        setTrack({commit}, track){
            commit(types.TRACK_ASSIGN, track);
        }
    }
}
