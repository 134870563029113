function page(path){
    return () => import('../../views/' + path).then(m => m.default || m)
}

export default {
    path: '/account',
    component: page('account.vue'),
    meta: {
        title: 'Transportation company specialized in making time-limited deliveries',
    },
    children: [
        {
            path: 'dashboard',
            name: 'account.dashboard',
            component: page('account/dashboard.vue'),
        },
        {
            path: 'orders',
            name: 'account.orders',
            component: page('account/orders.vue'),
        },
        {
            path: 'order/:id',
            name: 'account.order',
            component: page('account/order.vue'),
        },
        {
            path: 'quotes',
            name: 'account.quotes',
            component: page('account/quotes.vue'),
        },
        {
            path: 'quote',
            name: 'account.quote',
            component: page('account/calculator.vue'),
        },
        {
            path: 'track',
            name: 'account.track',
            component: page('account/track.vue'),
        },
        {
            path: 'main',
            name: 'account.main',
            component: page('account/account.vue'),
        },
        {
            path: 'data',
            name: 'account.data',
            component: page('account/data.vue'),
        },
        {
            path: 'payment',
            name: 'account.payment',
            component: page('account/payment.vue'),
        },
        {
            path: 'notification',
            name: 'account.notification',
            component: page('account/notification.vue'),
        },
        {
            path: 'logout',
            name: 'account.logout',
            component: page('account/logout.vue'),
        },
        {
            path: 'calculator',
            name: 'account.calculator',
            component: page('account/calculator.vue'),
        },
    ]
}
