import * as types from '../mutation-types'

export const calculator = {
    namespaced: true,
    state: {
        form: null,
        save: false
    },
    getters: {
        form: state => state.form,
        save: state => state.save,
    },
    mutations: {
        [types.FORM_ASSIGN](state, {form}) {
            state.form = form;
        },
        [types.FORM_REMOVE](state){
            state.form = null
        },
        [types.ENABLE_SAVE_FORM_FLAG](state){
            state.save = true
        },
        [types.DISABLE_SAVE_FORM_FLAG](state){
            state.save = false
        }

    },
    actions: {
        setForm({ commit }, form){
            commit(types.FORM_ASSIGN, form)
        },
        removeForm({commit}){
            commit(types.FORM_REMOVE)
        },
        setFlag({commit}){
            commit(types.ENABLE_SAVE_FORM_FLAG)
        },
        removeFlag({commit}){
            commit(types.DISABLE_SAVE_FORM_FLAG)
        }

    }
}
