import Vue from 'vue'
import Vuex from 'vuex'

import * as types from './mutation-types'

import {calculator} from './modules/calculator'
import {track} from './modules/track'
import {auth} from './modules/auth'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
      calculator: calculator,
      track: track,
      auth: auth
  }
})
