export const FORM_ASSIGN = 'FORM_ASSIGN'
export const FORM_REMOVE = 'FORM_REMOVE'
export const ENABLE_SAVE_FORM_FLAG = 'ENABLE_SAVE_FORM_FLAG'
export const DISABLE_SAVE_FORM_FLAG = 'DISABLE_SAVE_FORM_FLAG'


export const TRACK_ASSIGN = 'TRACK_ASSIGN'

export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const SAVE_ROUTE = 'SAVE_ROUTE'
export const REMOVE_ROUTE = 'REMOVE_ROUTE'
