<template>
    <div class="second-silver p-relative w-100 h-100 preloader pb-8">
        <img class="logo" src="@/assets/logo/logo-mini-dark.svg" alt="">
        <img class="rotate logo" src="@/assets/logo/preloading.svg" alt="">
    </div>
</template>

<script>
export default {
    name: 'Preloader',
}
</script>

<style lang="scss">
.preloader{
    z-index: 999999999;
    .logo{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .rotate{
        -webkit-animation: rotating 5s linear infinite;

    }
    @-webkit-keyframes rotating {
        from{
            -webkit-transform: rotate(0deg);
        }
        to{
            -webkit-transform: rotate(360deg);
        }
    }

}
</style>
