import axios from 'axios'
import * as types from '../mutation-types'
import Cookies from 'js-cookie'

export const auth = {
    namespaced: true,

    state: {
        user: null,
        token: Cookies.get('token'),
        toroute: null,
    },
    getters: {
        user: state => state.user,
        toroute: state => state.toroute,
        token: state => state.token,
        check: state => state.user !== null,
        id: state => state.user.id
    },
    mutations: {
        [types.SAVE_TOKEN] (state, { token, remember }) {
            state.token = token
            Cookies.set('token', token, { expires: remember ? 365 : null })
        },
        [types.FETCH_USER_SUCCESS] (state, { user }) {
            state.user = user
        },
        [types.FETCH_USER_FAILURE] (state) {
            state.token = null
            Cookies.remove('token')
        },
        [types.LOGOUT] (state) {
            state.user = null
            state.token = null

            Cookies.remove('token')
        },
        [types.UPDATE_USER] (state, { user }) {
            state.user = user
        },
        [types.SAVE_ROUTE] (state, to){
            state.toroute = to
        },
        [types.REMOVE_ROUTE] (state){
            state.toroute = null
        }
    },
    actions: {
        saveToken ({ commit, dispatch }, payload) {
            commit(types.SAVE_TOKEN, payload)
        },
        async fetchUser ({ commit }) {
            try {
                const { data } = await axios.get(process.env.VUE_APP_API + 'customers/v1/user')

                commit(types.FETCH_USER_SUCCESS, { user: data })
            } catch (e) {
                commit(types.FETCH_USER_FAILURE)
            }
        },
        updateUser ({ commit }, payload) {
            commit(types.UPDATE_USER, payload)
        },
        async logout ({ commit }) {
            try {
                await axios.post(process.env.VUE_APP_API + 'customers/v1/logout')
            } catch (e) { }

            commit(types.LOGOUT)
        },
        async fetchOauthUrl (ctx, { provider }) {
            const { data } = await axios.post(`/api/oauth/${provider}`)

            return data.url
        },
        saveRoute({commit}, route){
            commit(types.SAVE_ROUTE, route)
        },
        removeRoute({commit}){
            commit(types.REMOVE_ROUTE)
        }

    }
}
